.center {

    margin-top: 10%;
}

.rp {
    top: -66.4688px;
    left: 4.70312px;
    width: 183px;
    height: 183px;
}