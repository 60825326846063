.main {
/*    background-image: url('../img/meeting-bg-fade.jpg'); */
    background-color: #d0cecf;
    background-repeat: no-repeat;
    object-fit: fill;
    background-size: 100% 100%;
    max-width: 100%;
    max-height: 100%;
}

.logoImg {
    position: fixed;
    z-index: 1;
    padding: 0em;
    margin: 0em;
    width: 230px;
    height: 80px;
}

.btn-vote {
    padding: 0.8em;
    border-radius: 100px;
    width: 8em;
    border-radius: 100px;
    background: #b92b27;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #1a4e9b, #29a8d2);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #1a4e9b, #29a8d2);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.btn-new {
    padding: 0.8em;
    width: 15em;
    border-radius: 100px;
    background: #b92b27;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #1a4e9b, #29a8d2);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #1a4e9b, #29a8d2);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */



}

@media only screen and (max-width:320px) {
    .logoImg {
        position: inherit;
    }
}

@media only screen and (min-width:321px) and (max-width:768px) {
    .logoImg {
        position: inherit;
        width: 150px;
        height: 70px;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px) {
    .logoImg {
        position: inherit;
        width: 150px;
        height: 70px;
    }
}

@media only screen and (min-width:1024px) {
    .logoImg {
        position: inherit;
        width: 150px;
        height: 70px;
    }
}
